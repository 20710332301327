
import axios, { AxiosError } from 'axios';
import { defineComponent, ref, onMounted, watch, Ref } from 'vue';
import useAlert from "@/composables/Alert";
import {
  CampHeader,
  CampEmptyListFeedback,
  CampTable,
  CampTableTd,
  CampTableTh,
  CampTableSortRowsByColumn,
  CampFooterPaginationControl,
} from '@/components';
import StockInfo from '@/views/Stock/Components/StockInfo.vue'
import moment from 'moment';
import { useLoaderStore } from "@/store/LoaderStore";
import ModalUploadInvoiceXMLFile from './Components/ModalUploadInvoiceXMLFile.vue';
import { IObjEmit, ISortComponentCore } from '@/components/Table/CampTableSortRowsByColumn.vue';
import { campGoToTopByScrollMode } from '@/composables/Helpers';
import { useFilterBaseV2Store } from '@/store/FilterBaseV2Store';
import { IFilterStockSearchForm } from '@/layout/header/partials/filters/Stock/FilterStockListPage.vue';

interface IFilterStockRequestParams extends IFilterStockSearchForm {
  page: number
}

export default defineComponent({
  name: "StockList",
  components: {
    CampHeader,
    CampEmptyListFeedback,
    CampTable,
    CampTableTd,
    CampTableTh,
    CampTableSortRowsByColumn,
    StockInfo,
    ModalUploadInvoiceXMLFile,
    CampFooterPaginationControl,
  },
  setup() {
    /** Variables */
    const { showTimeAlert } = useAlert()
    const openModalInfoRef = ref(false)
    const stockList = ref<any[] | []>([])
    const detailList = ref()
    const loaderStore = useLoaderStore()
    const isLoadList = ref(false)
    const filterDom = ref('')
    const pageID = ref(1)
    const pageTotal = ref(1)
    const inputEventFromXMLFileList = ref <Event | null>(null)
    const toggleModalUploadInvoice = ref(false)
    const sortComponentCore = ref<ISortComponentCore>({
      currentColumn: "",
      rows: {},
      chronology: []
    })
    const filterBaseV2Store = useFilterBaseV2Store()
    const searchParams = ref<IFilterStockRequestParams>({
      oldest: null,
      movement_id: null,
      movement_type: null,
      responsible: null,
      startDate: null,
      endDate: null,
      page: 1
    })

    /** Methods */
    async function getStock(params: IFilterStockRequestParams) {
      try {
        const response = await axios.get(`/api/getStockByCompanyOrStoreInSession`,{ params })
        pageTotal.value = response.data.data.last_page
        stockList.value = response.data.data.data
        sortComponentCore.value.rows = stockList.value
      } catch (error) {
        if(error instanceof AxiosError)
          showTimeAlert(error.response?.data.message, "error")
        else
          showTimeAlert("Algo deu errado!", "error")
      } finally {
        isLoadList.value = false
        loaderStore.close()
      }
    }

    async function handleGetStock() {
      isLoadList.value = true

      pageID.value = 1
      searchParams.value.page = 1
      filterBaseV2Store.setPageID(1)

      const searchForm = filterBaseV2Store.getSearchForm<IFilterStockSearchForm | null>()
      if(searchForm)
        searchParams.value = { ...searchForm, page: searchParams.value.page }

      await getStock(searchParams.value)
    }

    async function handleGetStockPagination(ID: number) {
      loaderStore.open()

      pageID.value = ID
      searchParams.value.page = ID
      filterBaseV2Store.setPageID(ID)

      await getStock(searchParams.value)

      campGoToTopByScrollMode(false)
    }

    function openModalInfo(list) {
      openModalInfoRef.value = !openModalInfoRef.value
      detailList.value = list
    }

    function sortRowsByColumn(obj: IObjEmit) {
      stockList.value = obj.sortedList as any[]
      sortComponentCore.value = obj.sortComponentCore
    }

    const handleXMLFileChange = async (evt: Event) => {
      inputEventFromXMLFileList.value = evt
      toggleModalUploadInvoice.value = !toggleModalUploadInvoice.value
    }

    /** Life Clycles */
    onMounted(() => handleGetStock())

    watch(() => filterBaseV2Store.checkForUpdates(), () => filterBaseV2Store.checkForUpdates() && handleGetStock())

    return {
      stockList,
      handleGetStock,
      handleGetStockPagination,
      openModalInfo,
      openModalInfoRef,
      detailList,
      isLoadList,
      filterDom,
      moment,
      inputEventFromXMLFileList,
      toggleModalUploadInvoice,
      handleXMLFileChange,
      sortRowsByColumn,
      sortComponentCore,
      pageID,
      pageTotal,
    }
  }
})
