import xml2js from 'xml2js';
import { Ref } from 'vue';

export const isXMLFile = (file: File): boolean => file.name.toLowerCase().endsWith('.xml');

const readFileAsync = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => resolve((event?.target as FileReader)?.result as string);

    reader.onerror = (error) => reject(error);

    reader.readAsText(file);
  });
};

export const convertXMLFileToObjectThroughEvent = async (event: Event, parsedData: Ref<any>, showTimeAlert: Function, XMLFile: any = {}) => {
  const fileInput = event.target as HTMLInputElement;
  const file = fileInput.files?.[0];

  if (file && isXMLFile(file)) {
    XMLFile.value = file;
    try {
      const textContent = await readFileAsync(file);
      const parser = new xml2js.Parser({ explicitArray: false });
      parser.parseString(textContent, (err, result) => {
        if (err) {
          showTimeAlert("Erro na análise de XML", "error");
          console.error('Error parsing XML:', err);
        } else {
          parsedData.value = result;
        }
      });
    } catch (error) {
      showTimeAlert("Erro na leitura do arquivo", "error");
      console.error('Error reading file:', error);
    } finally {
      fileInput.value = ""
    }
  } else {
    showTimeAlert("Formato inválido. Por favor selecione um arquivo com formato XML", "error");
  }
};

export const getXMLFileContent = async (file: File, showTimeAlert: Function) => {
  if (file && isXMLFile(file)) {
    try {
      return readFileAsync(file);
    } catch (error) {
      showTimeAlert("Erro na leitura do arquivo", "error");
      console.error('Error reading file:', error);
      return null
    }
  } else {
    showTimeAlert("Formato inválido. Por favor selecione um arquivo com formato XML", "error");
    return null
  }
};

export function convertXMLElementToObject(tag: Element, parsedData: Ref<any>, showTimeAlert: Function) {
  const xmlString = tag.outerHTML;
  try {
    xml2js.parseString(xmlString, { explicitArray: false }, (err, result) => {
      if (err)
        throw err;
      parsedData.value = result
    });
  } catch (error) {
    showTimeAlert("Erro ao converter informações", "error");
    console.error('Error converting information:', error);
    return null
  }
  return null
}
