
import { PropType, Ref, defineComponent, ref, watch } from "vue";
import { Modal } from "bootstrap";
import useAlert from "@/composables/Alert"
import { uploadMediaFromClient } from "@/services/AzureBlobService";
import { useLoaderStore } from "@/store/LoaderStore";
import axios, { AxiosError } from "axios";
import moment from 'moment';
import {  CampTable, CampTableTd, CampTableTh} from "@/components"
import { convertXMLElementToObject, getXMLFileContent } from "@/services/XMLFileParseService";

type TIde = {
  natOp: string
  cNF: string
  dhEmi: string
  serie: string
  nNF: string
}

interface IIde {
  ide: TIde
}

type TEmit = {
  CNPJ: string
  xFant: string
  xNome: string
}
interface IEmit {
  emit: TEmit
}

type TDet = {
  prod: {
    xProd: string 
    cEAN: string
    cProd: string
    qTrib: string
    CFOP: string
  }
}
interface IDet {
  det: TDet[]
}

type TDest = {
  CNPJ: string
  xNome: string
}
interface IDest {
  dest: TDest
}

interface INFe {
  ide: TIde,
  emit: TEmit,
  dest: TDest,
  det: TDet[],
}

const INIT_EMIT: IEmit = {
  emit: {
    CNPJ: '',
    xFant: '',
    xNome: '',
  }
}
const INIT_DEST: IDest = {
  dest: {
    CNPJ: '',
    xNome: '',
  }
}
const INIT_IDE: IIde = {
  ide: {
    natOp: '',
    cNF: '',
    dhEmi: '',
    serie: '',
    nNF: '',
  }
}
const INIT_DET: IDet = {
  det: []
}
const INIT_NFe: INFe = {
  emit: { ...INIT_EMIT.emit },
  dest: { ...INIT_DEST.dest },
  ide: { ...INIT_IDE.ide },
  det: { ...INIT_DET.det },
}

export async function handleXMLContent (
  file: File,
  XMLFile: Ref<File | undefined>,
  parsedXmlObjEmit: Ref<IEmit>,
  parsedXmlObjDest: Ref<IDest>,
  parsedXmlObjIde: Ref<IIde>,
  parsedXmlObjDet: Ref<IDet>,
  parsedXmlObjAux: Ref<any>,
  parsedXMLData: Ref<INFe>,
  showTimeAlert: Function
): Promise<boolean> {
  if (!file)
    return true

  XMLFile.value = file

  const xmlString = await getXMLFileContent(file, showTimeAlert)
  if(typeof xmlString !== "string")
    return true

  const parser = new DOMParser();
  let doc: Document

  try {
    doc = parser.parseFromString(xmlString, "text/xml");
    if (doc.documentElement && doc.documentElement.nodeName === "parsererror") {
      const error = doc.documentElement.textContent;
      console.error("Parsing error:", error);
      showTimeAlert("Não foi possível obter conteúdo do Arquivo", "error")
      return true
    }
  } catch (error) {
    console.error(error)
    showTimeAlert("Não foi possível obter conteúdo do Arquivo", "error")
    return true
  }

  const $emit = doc.querySelector("emit")
  const $dest = doc.querySelector("dest")
  const $ide = doc.querySelector("ide")
  const $detList = doc.querySelectorAll("det")

  if (!$ide || !$emit || !$dest || !$detList)
    return true

  convertXMLElementToObject($emit, parsedXmlObjEmit, showTimeAlert)
  convertXMLElementToObject($dest, parsedXmlObjDest, showTimeAlert)
  convertXMLElementToObject($ide, parsedXmlObjIde, showTimeAlert)
  parsedXmlObjDet.value.det = new Array()
  $detList.forEach(($det, ID) => {
    convertXMLElementToObject(($det), parsedXmlObjAux, showTimeAlert)
    parsedXmlObjDet.value.det[ID] = parsedXmlObjAux.value.det
  })

  if (!parsedXmlObjEmit.value) {
    showTimeAlert("Não foi possível obter dados do Emitente", "error")
    console.error(parsedXmlObjEmit.value)
    return true
  }

  if (!parsedXmlObjDest.value) {
    showTimeAlert("Não foi possível obter dados do Destinatário", "error")
    console.error(parsedXmlObjDest.value)
    return true
  }

  if (!parsedXmlObjIde.value) {
    showTimeAlert("Não foi possível obter dados da Nota Fiscal", "error")
    console.error(parsedXmlObjIde.value)
    return true
  }

  if (parsedXmlObjDet.value.det.length < 1) {
    showTimeAlert("Não foi possível obter dados dos ítens", "error")
    console.error(parsedXmlObjDet.value.det)
    return true
  }

  const data = {
    emit: parsedXmlObjEmit.value.emit,
    dest: parsedXmlObjDest.value.dest,
    ide: parsedXmlObjIde.value.ide,
    det: parsedXmlObjDet.value.det,
  }

  parsedXMLData.value = JSON.parse(JSON.stringify(data))

  return false
}

type TCheckItems = {
  cEAN: string,
  cProd: string,
  CFOP: string,
}
interface INFeInfo {
  parsedXMLData: INFe | null
  XMLFile: File | undefined
  blobName: string | null
  itemsChecked: {
    company: string | null
    store: string | null
    storeByCompany: string | null
    auxItems: string[]
    stockItems: string[]
    giftItems: string[]
  } | null | undefined
}
const initInvoiceInfo: INFeInfo = {
  parsedXMLData: null,
  XMLFile: undefined,
  blobName: "",
  itemsChecked: null,
}
type TResult = {
  code: number,
  items: {
    desc: string
    cod: string
    sku: string
    qtd: string
  }[]
}
interface IResult {
  success: TResult,
  ignored: TResult,
  inStock: TResult,
  unregistered: TResult,
}
interface INote {
  cnpj_companhia: string,
  nome_fantasia_companhia: string,
  cnpj_loja: string,
  nome_loja: string,
  numero_nota_fiscal: string,
  data_hora_nota: string,
  status: string,
  itens: [
    {
      codigo_interno: string,
      codigo_de_barras: string,
      descricao: string,
      qtd: number
    }
  ]
}

interface ISuccessFeedbackResponse {
  error: boolean
  message: string
  data: {
    code: number
    data: {
      result: IResult,
      note: INote,
    }
  }
  errorCode: string
}

export function removeIncludingFrom(char: string, url: string) {
  const index = url.indexOf(char);
  if (index > -1) {
    return url.slice(0, index);
  } else {
    return url;
  }
}

export default defineComponent({
  name: "ModalUploadInvoiceXMLFile",
  components: {
    CampTable,
    CampTableTd,
    CampTableTh
  },
  props: {
    header: {
      type: String,
      default: "Upload de Nota Fiscal"
    },
    maxUploads: {
      type: Number,
      default: 3
    },
    toggleModalUploadInvoice: {
      type: Boolean,
      required: true,
    },
    inputEventFromXMLFileList: {
      required: true
    },
    updateStock: {
      type: Function as PropType<() => void>,
      required: true,
    }
  },
  setup(props, { emit }) {
    // Variables
    let auxModal
    const loaderStore = useLoaderStore()
    const { showTimeAlert } = useAlert()
    const parsedXMLData = ref<INFe>(JSON.parse(JSON.stringify(INIT_NFe)))
    const parsedXmlObjEmit = ref<IEmit>(JSON.parse(JSON.stringify(INIT_EMIT)))
    const parsedXmlObjDest = ref<IDest>(JSON.parse(JSON.stringify(INIT_DEST)))
    const parsedXmlObjIde = ref<IIde>(JSON.parse(JSON.stringify(INIT_IDE)))
    const parsedXmlObjDet = ref<IDet>(JSON.parse(JSON.stringify(INIT_DET)))
    const parsedXmlObjAux = ref<any>(null)
    const XMLFile = ref<File | undefined>();
    const invoiceInfoList = ref<INFeInfo[]>(new Array)
    const successFeedback = ref<ISuccessFeedbackResponse[]>(new Array)
    const failFeedback = ref<any[]>(new Array)

    // Functions
    const resetStates = () => {
      invoiceInfoList.value = new Array
      successFeedback.value = new Array
      failFeedback.value = new Array
      parsedXMLData.value = JSON.parse(JSON.stringify(INIT_NFe))
      XMLFile.value = undefined
    }

    const openModal = (id: string) => {
      const auxElement = document.querySelector(`#${id}`);
      auxModal = new Modal(auxElement);
      auxModal.show();
    }

    async function handleInputEventFromXMLFileList(evt: any) {
      const files: FileList | null = (evt.target as HTMLInputElement).files;
      if(!files)
        return

      const fileListArray: File[] = Array.from(files);
      if((fileListArray.length + invoiceInfoList.value.length) > props.maxUploads)
        showTimeAlert(`Só é possível enviar um total de ${props.maxUploads} notas fiscais.`, "error")

      const totalRemaining = props.maxUploads - invoiceInfoList.value.length
      const promises1 = fileListArray
        .map(async (file) => {
          const isError = await handleXMLContent(file, XMLFile, parsedXmlObjEmit, parsedXmlObjDest, parsedXmlObjIde, parsedXmlObjDet, parsedXmlObjAux, parsedXMLData, showTimeAlert)
          if(!isError) {
            return <{data: INFe, file: File}>{
              data: JSON.parse(JSON.stringify(parsedXMLData.value)),
              file
            }
          }
          null
        })
        .slice(0, totalRemaining)

      const results1 = (await Promise.all(promises1)).filter(obj => obj !== null)
      const promises2 = results1.map(async el => {
        const indexArray = invoiceInfoList.value.length
        if(el)
          await hanldeParsedXMLData(el.data, indexArray, el.file)
      })
      await Promise.all(promises2)
    }

    async function checkInvoiceItems(
      company_cnpj: string,
      store_cnpj: string,
      movement_id: string,
      items: TCheckItems[],
      index: number,
    ) {
      loaderStore.open()
        try {
          const bodyToPost = {
            company_cnpj,
            store_cnpj,
            movement_id,
            items
          }
          const result = await axios.post(`/api/checkInvoiceItems`, bodyToPost)
          invoiceInfoList.value[index].itemsChecked = result?.data?.data
        } catch (error) {
          if(error instanceof AxiosError) {
            if(error.response) {
              showTimeAlert(error.response?.data.message, "error")
            }
          } else {
            showTimeAlert("Algo deu errado!", "error")
          }
        } finally {
          loaderStore.close()
        }
    }

    async function getNoteURLIfExists(companyCNPJ: number | string, storeCNPJ:  number | string, movementId:  number | string, ) {
      try {
        return {
          error: null,
          data: (await axios.get(`/api/getNoteURL?companyCNPJ=${companyCNPJ}&storeCNPJ=${storeCNPJ}&movementId=${movementId}`)).data
        }
      } catch (error) {
        if(error instanceof AxiosError) {
          // showTimeAlert(error.response?.data.message, "error")
          return {
            error: error.response?.data.message,
            data: null
          }
        } else {
          // showTimeAlert("Algo deu errado!", "error")
          return {
            error: "Internal server error",
            data: null
          }
        }
      }
    }

    async function hanldeParsedXMLData(data: INFe, index: number, file?: File) {
      if(invoiceInfoList.value.find(el => el.XMLFile?.name === file?.name))
        return showTimeAlert('Arquivo já adicionado', 'error')
      invoiceInfoList.value[index] = { ...initInvoiceInfo }
      invoiceInfoList.value[index].parsedXMLData = { ...data }
      invoiceInfoList.value[index].XMLFile = file
      invoiceInfoList.value[index].blobName = null
      if(invoiceInfoList.value[index].XMLFile)
        invoiceInfoList.value[index].blobName = `notes/${new Date().getTime()}@${invoiceInfoList.value[index].XMLFile?.name}`

      const checkItems: TCheckItems[] = data.det.map(el => ({
        cEAN: el.prod.cEAN,
        cProd: el.prod.cProd,
        CFOP: el.prod.CFOP
      }))

      await checkInvoiceItems(
        data.emit.CNPJ,
        data.dest.CNPJ,
        `${data.ide.nNF}/${data.ide.serie}`,
        checkItems,
        index
      )
    }

    const handleXMLFileChange = async (evt: Event) => {
      await handleInputEventFromXMLFileList(evt)
    }

    const removeInvoiceInfo = (index: number) => invoiceInfoList.value = invoiceInfoList.value.filter((el, i) => index !== i && ({ ...el }))

    async function sendXMLFIle(file: File, blobName: string) {
      try {
        const { request } = (await uploadMediaFromClient(file, blobName))._response
        return {
          error: null,
          url: request.url
        }
      } catch (error) {
        // showTimeAlert('Erro ao salvar media, tente mais tarde!', 'error')
        return {
          error: "Erro ao salvar media",
          url: null
        }
      }
    }

    async function sendURL(url: string) {
      try {
        return {
          error: null,
          data: (await axios.post(`/api/proccessNoteFromClient`, { url })).data
        }
      } catch (error) {
        if(error instanceof AxiosError) {
          // showTimeAlert(error.response?.data.message, "error")
          return {
            error: error.response?.data.message,
            data: null
          }
        } else {
          // showTimeAlert("Algo deu errado!", "error")
          return {
            error: "Internal server error",
            data: null
          }
        }
      }
    }

    async function fire() {
      successFeedback.value = new Array();
      failFeedback.value = new Array();
      const sendList = invoiceInfoList.value.map((el) => ({
        file: el.XMLFile,
        blobName: el.blobName,
        companyCNPJ: el.parsedXMLData?.emit.CNPJ,
        storeCNPJ: el.parsedXMLData?.dest.CNPJ,
        movementId: el.parsedXMLData?.ide.cNF,
      }));
      loaderStore.open();

      // Send the XML files simultaneously
      const promises = sendList.map(async (el) => {
        if (el.file && el.blobName && el.companyCNPJ && el.storeCNPJ && el.movementId) {
          const responseNote = await getNoteURLIfExists(el.companyCNPJ, el.storeCNPJ, el.movementId)
          if(responseNote.data?.storage_url) {
            const data = await sendURL(removeIncludingFrom("?", responseNote.data?.storage_url));
            return (data.error) ? { error: data.error } : { data: data.data };
          } else {
            const response = await sendXMLFIle(el.file, el.blobName);
            if (response.url) {
              const data = await sendURL(removeIncludingFrom("?", response.url));
              return (data.error) ? { error: data.error } : { data: data.data };
            } else {
              return { error: response.error };
            }
          }
        }
      });

      // Wait for all the promises to be completed
      const results = await Promise.all(promises);

      // Classify the results between success and failure
      for (const result of results) {
        if (result) {
          if (result.data) {
            successFeedback.value.push(result.data);
          } else if (result.error) {
            failFeedback.value.push(result.error);
          }
        }
      }

      props.updateStock()

      loaderStore.close();
    }

    async function initNewPost(evt: Event) {
      resetStates()
      await handleInputEventFromXMLFileList(evt)
    }

    // Life Cycles
    watch(() => props.toggleModalUploadInvoice, async () => {
      resetStates()
      openModal("ModalUploadInvoiceXMLFile")
      await handleInputEventFromXMLFileList(props.inputEventFromXMLFileList)
    })

    return {
      invoiceInfoList,
      successFeedback,
      failFeedback,
      handleXMLFileChange,
      handleInputEventFromXMLFileList,
      removeInvoiceInfo,
      fire,
      initNewPost,
      moment
    }
  }
})
